#popup
  position: fixed
  width: 100%
  height: 100%
  background-color: rgba(0,0,0,.3)
  top: 0
  left: 0
  bottom: 0
  z-index: 1
  padding: 20px

.close-button
  background-color: white
  padding: 5px
  width: 20px
  border-radius: 50%
  display: block
  position: absolute
  top: 10px
  left: 10px
  z-index: 2

.popup-window
  background-color: white
  border-radius: 10px
  height: 100%
  position: relative
  box-shadow: 0 0 10px rgba(0,0,0,.5)
  padding: 20px

.popup-content-container
  width: 100%
  height: 100%
  overflow: auto
  display: grid
  align-items: center

.popup-content-container

  .diagram-container, .graph-container, .calculations-container, .information-container, .downloads-container
    min-width: 240px
    min-height: max-content
    padding-right: 15px

  .value
    width: 100%
    max-width: max-content
    min-width: 0px
    overflow-wrap: break-word
    text-align: left
    margin: auto
    margin-top: 20px