@use '../App'
  
.calculations-container
  @extend %graphic-container
  align-content: center

.value-name
  font-size: 60px
  display: block
  text-align: center

.value
  font-size: 50px
  text-align: center
  width: 40vw
  display: block
  margin-top: 20px
  overflow: hidden
  text-overflow: ellipsis

@media(max-width: 820px)
  .value
    width: 80vw
    min-width: 200px

@media(max-width: 420px)
  .value-name
    font-size: 40px
  
  .value
    font-size: 30px
