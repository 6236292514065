$primary-color: pink
$font: 'Roboto', sans-serif
$footer-height: 52px
$cell-border: 1px solid gray
$min-width: 320px

%graphic-container
  width: 100%
  height: 100%
  display: grid
  align-items: center
  justify-items: center

body
  margin: 0
  font-family: $font

div
  //border: 1px solid black
  box-sizing: border-box

button
  cursor: pointer

.centered-container
  display: grid
  grid-template-columns: repeat(2, minmax(0, 1fr))
  grid-template-rows: repeat(2, minmax(0, 1fr))
  width: 100%
  height: 100vh
  min-height: 500px
  padding: 20px
  padding-bottom: $footer-height + 15px
  cursor: zoom-in

.cell
  width: 100%
  height: 100%
  padding: 20px

.cell:nth-child(1), .cell:nth-child(2)
  border-bottom: $cell-border

.cell:nth-child(odd)
  border-right: $cell-border

@media(max-width: 820px)
  .centered-container
    grid-template-columns: minmax(0, 1fr)
    grid-template-rows: repeat(4, auto)
    width: 100%
    min-width: $min-width
    height: max-content
    min-height: max-content

  .centered-container > .cell
    border: none
    border-bottom: $cell-border

  .centered-container > .cell:nth-child(4)
    border: none

  .centered-container > .cell:nth-child(1)
    min-height: 90vw
