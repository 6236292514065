.information-container
  max-width: 1000px
  margin: auto
  
h1
  font-size: 30px
  font-weight: 700
  margin: 0
  padding-bottom: 20px
  padding-top: 20px

.upload-instructions
  
  span
    display: block

ol, ul
  line-height: 2
  margin: 0
  padding-left: 40px
  padding-bottom: 20px
  font-size: 14px

ul
  list-style-type: circle

li
  padding-bottom: 10px

.icon
  vertical-align: sub

.formula-li

  > span, > img
    display: block
    
  img
    padding: 10px 0px

.asterisk
  color: rgb(105, 179, 162)
  font-weight: 700
