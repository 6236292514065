@use '../App'

.footer
  background-color: white
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  display: grid
  grid-template-columns: repeat(3, minmax(0, 1fr))
  padding: 10px 20px
  height: App.$footer-height
  align-items: center
  box-shadow: 0 -1px 10px rgba(0,0,0,.3)

#hidden-file-input
  display: none

#displayed-file-input
  justify-self: start
  display: grid
  grid-template-columns: repeat(2, auto)
  grid-gap: 15px
  align-items: center
  font-size: 14px

  button
    font-family: App.$font
    background-color: hsl(0,0%,90%)
    border: none
    border-radius: 5px
    padding: 8px
    display: block
    width: max-content

  span
    max-width: 100%
    display: block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

#download-button
  justify-self: center
  width: 30px

#help-button
  justify-self: end
  width: 30px

@media(max-width: 540px)
  #displayed-file-input > span
    display: none

@media(max-width: 295px)
  .footer
    grid-template-columns: repeat(3, auto)
    min-width: 204px