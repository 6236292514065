.downloads-container
  width: max-content
  margin: auto

.row
  padding: 10px
  font-size: 20px
  display: grid
  grid-template-columns: max-content 150px max-content
  justify-items: center
  align-items: center

  a > img
    width: 30px

  span:nth-child(3)
    color: gray